// Generated by Framer (8184e1b)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["r9XN9LSYq"];

const serializationHash = "framer-SQXeL"

const variantClassNames = {r9XN9LSYq: "framer-v-dyhbx6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "r9XN9LSYq", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-dyhbx6", className, classNames)} data-border data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"r9XN9LSYq"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-6f65be5b-d434-40c7-9377-eff99acd05cd, rgb(143, 143, 143)) /* {\"name\":\"Secondary Font Color\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 26, borderBottomRightRadius: 26, borderTopLeftRadius: 26, borderTopRightRadius: 26, ...style}}><SVG className={"framer-1nmhwmc"} data-framer-name={"graphic"} layout={"position"} layoutDependency={layoutDependency} layoutId={"HvV_x3NXZ"} opacity={1} style={{backgroundColor: "rgba(0, 0, 0, 0)"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 9 11\"><path d=\"M 7.215 8.924 L 6.432 8.055 L 8.658 5.586 L 9.18 5.194 L 9.118 4.99 L 8.489 5.075 L 0 5.075 L 0 3.832 L 8.489 3.832 L 9.118 3.934 L 9.18 3.73 L 8.658 3.338 L 6.432 0.869 L 7.215 0 L 10.838 4.019 L 10.838 4.905 Z\" transform=\"translate(-0.92 1.012) rotate(90 5.419 4.462)\" fill=\"var(--token-6f65be5b-d434-40c7-9377-eff99acd05cd, rgb(143, 143, 143)) /* {&quot;name&quot;:&quot;Secondary Font Color&quot;} */\"></path></svg>"} svgContentId={2163721285} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-SQXeL[data-border=\"true\"]::after, .framer-SQXeL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-SQXeL.framer-2e7mne, .framer-SQXeL .framer-2e7mne { display: block; }", ".framer-SQXeL.framer-dyhbx6 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: auto; justify-content: center; overflow: visible; padding: 8px 14px 8px 14px; position: relative; width: 28px; }", ".framer-SQXeL .framer-1nmhwmc { flex: none; height: 11px; position: relative; width: 9px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-SQXeL.framer-dyhbx6 { gap: 0px; } .framer-SQXeL.framer-dyhbx6 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-SQXeL.framer-dyhbx6 > :first-child { margin-left: 0px; } .framer-SQXeL.framer-dyhbx6 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 28
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FrameryE27HRfqk: React.ComponentType<Props> = withCSS(Component, css, "framer-SQXeL") as typeof Component;
export default FrameryE27HRfqk;

FrameryE27HRfqk.displayName = "Arrow";

FrameryE27HRfqk.defaultProps = {height: 27, width: 28};

addFonts(FrameryE27HRfqk, [])